var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.viewAlert
        ? _c(
            "div",
            { staticClass: "flex justify-items-center" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "mt-5 text-warning mb-4 h-auto",
                  attrs: {
                    title: _vm.$t("alerta"),
                    color: "rgb(231, 154, 23)",
                    active: "true",
                  },
                },
                [
                  _c("span", [
                    _c("p", [_vm._v(_vm._s(_vm.$t("plan.already_used")))]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full grid justify-items-center" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass:
                            "float-left mt-1 hover:animate-bounce transform hover:scale-110",
                          attrs: { type: "flat" },
                          on: { click: _vm.openPopup },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("plan.subscribe_here")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("PlanSubscribePopup", {
        attrs: { visible: _vm.showSubscribePopup, usageData: _vm.usageData },
        on: { close: _vm.closePopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }